import React from "react";
import styled from "styled-components";
import Fade from "react-reveal";
import "./styles.css";

function Section4() {
  const [scrollElement, setScrollElement] = React.useState({
    Phase1: "0%",
    Phase2: "0%",
    Phase3: "0%",
    Phase4: "0%",
  });

  const scrollUpdate = () => {
    let current = "";
    const sections = document.querySelectorAll("section");
    const Progress = document.getElementById("Progress");
    const { pageYOffset } = window;
    const progressTop = Progress.offsetTop;
    const progressheight = Progress.offsetHeight;
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionTop - sectionHeight) {
        current = section.getAttribute("id");
        if (current) {
          const sec = document.getElementById(current);
          const secTop = sec.offsetTop;
          const secHeight = sec.offsetHeight;
          let value = Math.round(window.scrollY + 250 - sec.offsetTop);
          console.log(current, value);
          // console.log((pageYOffset/(sectionTop-sectionHeight/6)))
          const updatedValue = {};

          if (value < 100 && value > 0) {
            value = value.toString() + "%";
          } else if (value > 100) {
            value = "100%";
          } else {
            value = "0%";
          }
          setScrollElement((prev) => ({ ...prev, [current]: value }));
        }
      } else if (pageYOffset <= progressTop - progressheight) {
        setScrollElement((prev) => ({
          ...prev,
          Phase1: "0%",
          Phase2: "0%",
          Phase3: "0%",
          Phase4: "0%",
        }));
      }
    });
  };

  React.useEffect(() => {
    scrollUpdate();
    window.addEventListener("scroll", scrollUpdate);
    return () => {
      window.removeEventListener("scroll", scrollUpdate);
    };
  }, []);
  console.log(scrollElement);

  return (
    <Container>
      <Wrap>
        <Title>Ape Map</Title>
        <Description>
          This Ape Map is a framework for our goals and where we want to take
          the project. We have a lot of ideas and concepts in the works, so
          we’ve outlined the plan in four main phases. It will evolve over time
          based on feedback from the community.{" "}
        </Description>
        <ProgressWrap id="Progress">
          {/* <Fade bottom> */}
          <Phase id="Phase1">
            <Left>
              <Box height={scrollElement.Phase1}>
                <Rect height={scrollElement.Phase1} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase1} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase1}>
              <Name height={scrollElement.Phase1}>Phase I</Name>
              <Desc height={scrollElement.Phase1}>
                100 Other Origins Collection
              </Desc>
              <ul>
                <li>Launch 100-unit Other Origins collection. ✓</li>
                <li>Open Merch Stash. ✓</li>
                <li>Update website. ✓</li>
                <li>Boost marketing efforts. ✓ </li>
                <li>Release concept art exploring Origins lore. ✓</li>
              </ul>
            </Right>
          </Phase>
          <Phase id="Phase2">
            <Left>
              <Box height={scrollElement.Phase2}>
                <Rect height={scrollElement.Phase2} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase2} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase2}>
              <Name height={scrollElement.Phase2}>Phase II</Name>
              <Desc height={scrollElement.Phase2}>
                Other Invaders Collection
              </Desc>
              <ul>
                <li>Launch Other Invaders collection.</li>
                <li>Formation of OriginDAO.</li>
                <li>Airdrop exclusive NFT to Invader holders.</li>
                <li>Announce special event.</li>
                <li>Release comicbook series exploring Faction lore.</li>
              </ul>
            </Right>
          </Phase>
          <Phase id="Phase3">
            <Left>
              <Box height={scrollElement.Phase3}>
                <Rect height={scrollElement.Phase3} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase3} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase3}>
              <Name height={scrollElement.Phase3}>Phase III</Name>
              <Desc height={scrollElement.Phase3}>The Game</Desc>
              <ul>
                <li>Launch [CLASSIFIED] Collection</li>
                <li>Distribute game soundtrack to all holders.</li>
                <li>
                  Upgrade website with NFT customization tools for [CLASSIFIED]
                  collection.
                </li>
                <li>Release steelbook of project content.</li>
                <li>Announce game beta event.</li>
                <li>Release game.</li>
              </ul>
            </Right>
          </Phase>
          <Phase id="Phase4">
            <Left>
              <Box height={scrollElement.Phase4}>
                <Rect height={scrollElement.Phase4} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase4} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase4}>
              <Name height={scrollElement.Phase4}>Phase IV</Name>
              <Desc height={scrollElement.Phase4}>
                {" "}
                Other Origins Studios x Creators
              </Desc>
              <Content height={scrollElement.Phase4}>
                After the completion of these phases, we’ll continue to build
                and maintain the Other Origins community. We are truly
                passionate about various styles of expression, and our ultimate
                vision is to empower other creators of all backgrounds and
                origins by providing them with the tools and resources necessary
                to do their best work. We look forward to seeing how they will
                shape the future of the Other Origins Metaverse!
              </Content>
            </Right>
          </Phase>
          {/* </Fade> */}
        </ProgressWrap>
      </Wrap>
      <Wrap>
        <Title>Stray Ape Army</Title>
        <Description>
          We’re a diverse group of creatives, artists, and developers, excited
          to build on the rapidly growing Solana ecosystem. You can find us on
          our Discord. We enjoy a good chat about almost anything!
        </Description>
        <Team>
          <TeamContainer>
            <a href="#l">
              <img src="/assests/Team Photos/Smuggy.png" alt="" />
            </a>
            <MemberName>Commander Smuggy</MemberName>
            <Position>Project Lead</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Skull.png" alt="" />
            </a>
            <MemberName>Lieutenant Skull</MemberName>
            <Position>Marketing Manager</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Stark.png" alt="" />
            </a>
            <MemberName>Sergeant Stark</MemberName>
            <Position>Technical Head</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Sonny.png" alt="" />
            </a>
            <MemberName>Casanova Sonny</MemberName>
            <Position>Community Manager</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Silas.png" alt="" />
            </a>
            <MemberName>Artisan Silas</MemberName>
            <Position>Art Director</Position>
          </TeamContainer>
        </Team>
      </Wrap>
    </Container>
  );
}

export default Section4;

const Container = styled.section`
  min-height: 100vh;
  background-color: #02080d;
  background-repeat: no-repeat;
  background-size: 100%;
  // min-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Wrap = styled.div`
  margin: 32px 32px;
  // margin-top: 64px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  max-width: 951px;
  width: 100%;
  min-width: 260px;
`;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  /* identical to box height, or 36px */
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 4px 0;
  margin-bottom: 36px;
  color: #ffb000;
`;

const Description = styled.p`
  // max-width: 951px;
  // // max-height: 88px;
  // width: 100%;
  margin: 0 32px;
  height: 100%;
  // min-width: 200px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 0em;
  /* or 22px */

  //   text-align: justify;
  display: flex;
  justify-content: center;
  color: #ffffff;

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const ProgressWrap = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Phase = styled.section`
  display: flex;
  max-width: 951px;
  width: 100%;
  margin-bottom: 30px;
`;

const Left = styled.div`
  margin-top: 32px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Box = styled.div`
  width: 40px;
  height: 40px;
  border: ${(props) =>
    props.height !== "0%" ? "3px solid #FFFFFF" : "3px solid #FFFFFF66"};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Rect = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Stick = styled.div`
  margin-top: 16px;
  position: relative;
  width: 6px;
  height: 220px;
  background: rgba(255, 255, 255, 0.25);
`;

const Progress = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => props.height};
  // height: 60%;
  background: #ffffff;
`;

const Right = styled.div`
  padding: 20px 32px;
  margin: 0 16px;
  height: fit-content;
  max-width: 951px;
  width: 100%;
  border: 3px solid
    ${(props) => (props.height !== "0%" ? "#ffffff" : "#ffffff66")};

  ul {
    li {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
    }
  }
`;

const Name = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) =>
    props.height !== "0%" ? "#ffb000" : "rgba(255, 255, 255, 0.4)"};
`;

const Desc = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Content = styled.div`
  margin: 24px 0;
  max-width: 702px;
  width: 100%;
  min-width: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Team = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 64px 0;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    transition: 0.3s;

    :hover {
      width: 200px;
      height: 200px;
    }
    width: 180px;
    height: 180px;
  }
  @media (max-width: 1000px) {
    margin: 32px 32px;
  }
`;

const MemberName = styled.div`
  font-family: Poppins;
  margin-top: 16px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffb000;
`;

const Position = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
`;
