import React from "react";
import styled from "styled-components";
import "./styles.css";

function Section5() {
  return (
    <Container className="discord__section">
      <Wrap>
        <img className="footer__img" src="assests/DiscordBanner.jpg" alt="" />
        <Right>
          <Title>Join the community</Title>
          <Description>
            The Other Origins Discord is growing fast! If you want to be an
            #OtherInvader it’s here. Join us for the latest announcements,
            presale info, sneak peeks, lore building, and more!
          </Description>
          <a class="connect_btn" href="#">
            <Join>
              {/* <DiscordImg>
                <img src="assests/Discord2.png" alt="" />
              </DiscordImg> */}
              <Text>Join our Discord</Text>
            </Join>
          </a>
        </Right>
      </Wrap>
    </Container>
  );
}

export default Section5;

const Container = styled.div`
  background: #7877e6;
`;

const Wrap = styled.div`
  display: flex;
  margin: auto;
  //  width: 100%;
  //   max-width 100%;
  //   height: auto;
  //  Object-fit: cover;

  @media (max-width: 1300px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;

const Right = styled.div`
  margin: 64px 64px;
  width: 50%;
  background: ##ff8100 !important;
`;

const Title = styled.div`
  margin: 0px 0;
  margin-bottom: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #000;
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  //text-align: justify;
  color: #000;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const Join = styled.div`
  max-width: 323px;
  min-width: 100px;
  height: 64px;
  box-sizing: border-box;
  margin: 16px 0;
  padding: 0;
  background: inherit;
  display: flex;
  transition: 0.5s;
  :hover {
    background: #02080d;
  }
`;

const DiscordImg = styled.div`
  width: 72px;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
  }
`;

const Text = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  width: 100%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    color: #fff;
    border: 2px solid #fff;
  }
`;
